import Header from './components/header';
import MapArea from './components/mapArea';
import Sidebar from './components/sidebar';

function App() {
  return (
    <div>
        {/* <Header/> */}
        <Sidebar/>
        <MapArea/>
    </div>
  );
}

export default App;
