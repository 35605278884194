import ImageLayer from 'ol/layer/Image';
import ImageWMS from 'ol/source/ImageWMS';

export default async function getImageLayer(layer_store, layer_name, setVisible = false) {
    const wmsLayer = new ImageLayer({
      source: new ImageWMS({
        url: 'https://geoserver.gramvaani.org:8443/geoserver/wms',
        params: { 'LAYERS': layer_store + ':' + layer_name },
        ratio: 1,
        serverType: 'geoserver',
      }),
      visible: setVisible,
    })
    return wmsLayer;
}