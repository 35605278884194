import { useEffect, useRef, useState } from "react";

import "ol/ol.css";
import { Icon, Style, Stroke, Fill } from "ol/style.js";

import XYZ from "ol/source/XYZ";
import { Map, View, Feature } from "ol";
import Point from "ol/geom/Point.js";
import VectorLayer from "ol/layer/Vector";
import TileLayer from "ol/layer/Tile";
import VectorSource from "ol/source/Vector";

import GeoJSON from "ol/format/GeoJSON";
import KML from "ol/format/KML.js";

import getStates from "../actions/getStates";
import getPlans from "../actions/getPlans";
import getVectorLayers from "../actions/getVectorLayers";
import getImageLayer from "../actions/getImageLayers";

import mapMarker from "../assets/map_marker.svg";
import well_mrker from "../assets/well_proposed.svg";
import wb_mrker from "../assets/waterbodies_proposed.svg";
import settlementIcon from "../assets/settlement_icon.svg";
import tcb_proposed from "../assets/tcb_proposed.svg";
import boulder_proposed from "../assets/boulder_proposed.svg";
import farm_pond_proposed from "../assets/farm_pond_proposed.svg";
import check_dam_proposed from "../assets/check_dam_proposed.svg";
import land_leveling_proposed from "../assets/land_leveling_proposed.svg";
import livelihood_proposed from "../assets/livelihood_proposed.svg"

//? Temprorary Imports
import landscape_icon from "../assets/eco.png"
import getWebGlLayers from "../actions/getWebGlLayers";
import ToggleButton from "./toggleButton";
import DownloadButton from "./buttons/download_button";
import FetchButton from "./buttons/fetch_button";

const MapArea = () => {
  let mapElement = useRef(null);
  let mapRef = useRef(null);

  //? Layers Ref Group
  let LayersArray = [
    {LayerRef : useRef(null), name : "Panchayat demographics", isRaster : false},
    {LayerRef : useRef(null), name : "Drainage", isRaster : false},
    {LayerRef : useRef(null), name : "Remote-Sensed Waterbodies", isRaster : false},
    {LayerRef : useRef(null), name : "Hydrological Boundries", isRaster : false},
    {LayerRef : useRef(null), name : "CLART", isRaster : true},
    {LayerRef : useRef(null), name : "Hydrological Variables", isRaster : false},
    {LayerRef : useRef(null), name : "NREGA", isRaster : false},
    {LayerRef : useRef(null), name : "Drought", isRaster : false},
    {LayerRef : useRef(null), name : "Terrain", isRaster : true},
    {LayerRef : useRef(null), name : "Administrative boundaries", isRaster : false},
  ]
  let ResourceLayersArray = [
    {LayerRef : useRef(null), name : "Settlement Layer"},
    {LayerRef : useRef(null), name : "Water Structure Layer"},
    {LayerRef : useRef(null), name : "Well Layer"},
  ]
  let PlanningLayersArray = [
    {LayerRef : useRef(null), name : "Agristructure Layer"},
    {LayerRef : useRef(null), name : "Livelihood Layer"},
  ]

  let markersLayer = useRef(null);

  const [stateData, setStateData] = useState(null);

  //? States for handling the Selectiion of states, district and block
  const [state, setState] = useState(null);
  const [district, setDistrict] = useState(null);
  const [block, setBlock] = useState(null);

  //? States for Handling the plan selection
  const [plans, setPlans] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  //? Dropdown Toggle Group
  const [stateToggle, setStateToggle] = useState(false);
  const [districtToggle, setDistrictToggle] = useState(false);
  const [blockToggle, setBlockToggle] = useState(false);
  const [planToggle, setPlanToggle] = useState(false);

  //? LULC Toggle Group
  const [lulcToggle1, setLulcToggle1] = useState(false)
  const [lulcToggle2, setLulcToggle2] = useState(false)
  const [lulcToggle3, setLulcToggle3] = useState(false)
  const [lulcYear1, setLulcYear1] = useState(null)
  const [lulcYear2, setLulcYear2] = useState(null)
  const [lulcYear3, setLulcYear3] = useState(null)

  //? Layers Present
  const [isLayersFetched, setIsLayersFetched] = useState(false)
  const [isOtherLayersFetched, setIsOtherLayersFetched] = useState(false);

  //? Miscellaneous States and Variables
  const [isLoading, setIsLoading] = useState(false);
  const [isLayersLoading, setIsLayersLoading] = useState(false);
  const [hrefData, setHrefData] = useState(null);
  const [currentLayers, setCurrentLayers] = useState(["Panchayat demographics","NREGA"]);

  const drainageColors = [
    "03045E",
    "023E8A",
    "0077B6",
    "0096C7",
    "00B4D8",
    "48CAE4",
    "90E0EF",
    "ADE8F4",
    "CAF0F8",
  ];
  const yearDataLulc = [
    {
      label: "2016-2017",
      value: "16_17",
    },
    {
      label: "2017-2018",
      value: "17_18",
    },
    {
      label : "2018-2019",
      value : "18_19",
    },
    {
      label : "2019-2020",
      value : "19_20"
    },
    {
      label : "2020-2021",
      value : "20_21"
    },
    {
      label : "2021-2022",
      value : "21_22"
    },
    {
      label : "2022-2023",
      value : "22_23"
    }
  ];
  const [bbox, setBBox] = useState(null);

  const getblockFeatures = async (block_name) => {
    let coordinates = null;

    let AdminURl = `https://geoserver.gramvaani.org:8443/geoserver/panchayat_boundaries/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=panchayat_boundaries:${block_name.toLowerCase()}_panchayat&outputFormat=application/json&screen=main`;

    try {
      await fetch(AdminURl)
        .then((res) => res.json())
        .then((Geojson) => {
          coordinates = Geojson.features[0].geometry.coordinates[0][0][0];
        });
    } catch (e) {
      console.log("error in fetching for : ", block_name);
      return [];
    }
    return coordinates;
  };

  const getStatesData = async () => {
    let data = await getStates();

    setStateData(data)

    let temp_blockNames = [];

    data.map((item) => {
      temp_blockNames.push(item.district[0].blocks[0].label);
    });

    let layer_features = await Promise.all(
      temp_blockNames.map((item) => {
        return getblockFeatures(item);
      })
    ).then((res) => {
      return res.map(
        (coord) =>
          new Feature({
            geometry: new Point(coord),
          })
      );
    });

    const StateMarkers = new Style({
      image: new Icon({
        src: mapMarker,
      }),
    });

    let StateLevelLayer = new VectorLayer({
      source: new VectorSource({
        features: layer_features,
      }),
      style: StateMarkers,
    });

    mapRef.current.addLayer(StateLevelLayer);

    markersLayer.current = StateLevelLayer;
  };

  const getDistrictData = async () => {
    let temp_blocks = state.district.map((item) => {
      return item.blocks[0].label;
    });

    let temp_coordinates = null;

    let layer_features = await Promise.all(
      temp_blocks.map((item) => {
        return getblockFeatures(item);
      })
    ).then((res) => {
      return res.map((coord) => {
        if (temp_coordinates == null) temp_coordinates = coord;

        return new Feature({
          geometry: new Point(coord),
        });
      });
    });

    const StateMarkers = new Style({
      image: new Icon({
        src: mapMarker,
      }),
    });

    let districtLevelLayer = new VectorLayer({
      source: new VectorSource({
        features: layer_features,
      }),
      style: StateMarkers,
    });

    mapRef.current.removeLayer(markersLayer.current);

    mapRef.current.addLayer(districtLevelLayer);

    markersLayer.current = districtLevelLayer;

    mapRef.current.getView().setCenter(temp_coordinates);
    mapRef.current.getView().setZoom(8);
  };

  const getBlockData = async () => {
    const temp_blocks = district.blocks.map((item) => {
      return item.label;
    });

    let temp_coordinates = null;

    //console.log(temp_blocks)

    let layer_features = await Promise.all(
      temp_blocks.map((item) => {
        return getblockFeatures(item);
      })
    ).then((res) => {
      return res.map((coord) => {
        if (temp_coordinates == null) temp_coordinates = coord;

        return new Feature({
          geometry: new Point(coord),
        });
      });
    });

    const StateMarkers = new Style({
      image: new Icon({
        src: mapMarker,
      }),
    });

    let blockLevelLayer = new VectorLayer({
      source: new VectorSource({
        features: layer_features,
      }),
      style: StateMarkers,
    });

    mapRef.current.removeLayer(markersLayer.current);

    mapRef.current.addLayer(blockLevelLayer);

    markersLayer.current = blockLevelLayer;

    mapRef.current.getView().setCenter(temp_coordinates);
    mapRef.current.getView().setZoom(11);
  };

  useEffect(() => {
    getStatesData();

    let BaseLayer = new TileLayer({
      source: new XYZ({
        url: "https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}",
        maxZoom: 30,
      }),
      visible: true,
    });

    const view = new View({
      center: [99.9, 23.6],
      zoom: 5,
      projection: "EPSG:4326",
    });

    const initialMap = new Map({
      target: mapElement.current,
      layers: [BaseLayer],
      view: view,
    });

    mapRef.current = initialMap;

    initialMap.on("click", (e) => {
      console.log(e.coordinate);
    });

    return () => {
      initialMap.setTarget(null);
    };
  }, []);

  useEffect(() => {
    if (state != null) {
      getDistrictData();
    }
    if (district != null) {
      getBlockData();
    }
  }, [state, district]);

  const fetchResourcesLayers = async () => {
    if (selectedPlan != null) {
      setIsLayersLoading(true)
      //? Code for settlement Layer
      let settlementLayer = await getVectorLayers(
        "resources",
        "hemlet_layer" + block.label.toLowerCase(),
        true,
        true,
        "settlement",
        selectedPlan.plan_id,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      );

      if (ResourceLayersArray[0].LayerRef.current != null) {
        mapRef.current.removeLayer(ResourceLayersArray[0].LayerRef.current);
      }

      mapRef.current.addLayer(settlementLayer);

      ResourceLayersArray[0].LayerRef.current = settlementLayer;

      ResourceLayersArray[0].LayerRef.current.setStyle(
        new Style({
          image: new Icon({ src: settlementIcon }),
        })
      );

      //? Code For Water Structures Layer
      let WaterStructuresLayer = await getVectorLayers(
        "works",
        "plan_layer_gw" + block.label.toLowerCase(),
        true,
        false,
        "plan_gw",
        selectedPlan.plan_id,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      );

      if (ResourceLayersArray[1].LayerRef.current != null) {
        mapRef.current.removeLayer(ResourceLayersArray[1].LayerRef.current);
      }

      mapRef.current.addLayer(WaterStructuresLayer);

      ResourceLayersArray[1].LayerRef.current = WaterStructuresLayer;

      ResourceLayersArray[1].LayerRef.current.setStyle(function (feature) {
        const status = feature.values_;

        if (status.work_type == "new farm pond") {
          return new Style({
            image: new Icon({ src: farm_pond_proposed }),
          });
        } else if (status.work_type == "new trench cum bund network") {
          return new Style({
            image: new Icon({ src: tcb_proposed }),
          });
        } else if (status.work_type == "new check dam") {
          return new Style({
            image: new Icon({ src: check_dam_proposed }),
          });
        } else {
          return new Style({
            image: new Icon({ src: boulder_proposed }),
          });
        }
      });

      //? Code for Well Layer
      let WellLayer = await getVectorLayers(
        "resources",
        "well_layer" + block.label.toLowerCase(),
        true,
        true,
        "well",
        selectedPlan.plan_id,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      );

      if (ResourceLayersArray[2].LayerRef.current != null) {
        mapRef.current.removeLayer(ResourceLayersArray[2].LayerRef.current);
      }

      mapRef.current.addLayer(WellLayer);
      ResourceLayersArray[2].LayerRef.current = WellLayer;

      ResourceLayersArray[2].LayerRef.current.setStyle(
        new Style({
          image: new Icon({ src: well_mrker }),
        })
      );

      setIsLayersLoading(false)
      setIsOtherLayersFetched(true)
    }
  };

  const fetchPlanningLayers = async () => {
      
    //? Code for Agri Structures Layer
      let AgriStructuresLayer = await getVectorLayers(
        "works",
        "plan_layer_agri" + block.label.toLowerCase(),
        true,
        false,
        "plan_agri",
        selectedPlan.plan_id,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      );

      if (PlanningLayersArray[0].LayerRef.current != null) {
        mapRef.current.removeLayer(PlanningLayersArray[0].LayerRef.current);
      }

      mapRef.current.addLayer(AgriStructuresLayer);
      PlanningLayersArray[0].LayerRef.current = AgriStructuresLayer;

      PlanningLayersArray[0].LayerRef.current.setStyle(function (feature) {
        const status = feature.values_;

        if (status.TYPE_OF_WO == "New farm pond") {
          return new Style({
            image: new Icon({ src: farm_pond_proposed }),
          });
        } else if (status.TYPE_OF_WO == "Land leveling") {
          return new Style({
            image: new Icon({ src: land_leveling_proposed }),
          });
        } else if (status.TYPE_OF_WO == "New well") {
          return new Style({
            image: new Icon({ src: well_mrker }),
          });
        } else {
          return new Style({
            image: new Icon({ src: wb_mrker }),
          });
        }
      });


      //? Code for Livelihood Layer
      let LivelihoodLayer = await getVectorLayers(
        "works",
        "hemlet_layer" + block.label.toLowerCase(),
        true,
        false,
        "livelihood",
        selectedPlan.plan_id,
        district.label.toLowerCase(),
        block.label.toLowerCase()
    );

    if (PlanningLayersArray[1].LayerRef.current != null) {
      mapRef.current.removeLayer(PlanningLayersArray[1].LayerRef.current);
    }

    mapRef.current.addLayer(LivelihoodLayer);
    PlanningLayersArray[1].LayerRef.current = LivelihoodLayer;

    PlanningLayersArray[1].LayerRef.current.setStyle(
      new Style({
        image: new Icon({ src: livelihood_proposed }),
      })
    );
    

  }

  const handleLocationChange = async () => {
    if (block != null) {
      setIsLoading(true);

      let currentActiveLayers = []

      let temp_plan = await getPlans(block.block_id);

      setPlans(temp_plan);

      let adminLayer = await getVectorLayers(
        "panchayat_boundaries",
        block.label.toLowerCase() + "_panchayat",
        true,
        true
      );

      //? Remove the admin Layer if exists to display only one admin layer at a time
      if (LayersArray[0].LayerRef.current != null) {
        mapRef.current.removeLayer(LayersArray[0].LayerRef.current);
      }

      mapRef.current.addLayer(adminLayer);
      currentActiveLayers.push(LayersArray[0].name)

      LayersArray[0].LayerRef.current = adminLayer;

      //? Centering the Map to the Selected Block
      const Vectorsource = adminLayer.getSource();
      Vectorsource.once("change", function (e) {
        if (Vectorsource.getState() === "ready") {
          const arr = Vectorsource.getExtent();
          setBBox(arr);
          const mapcenter = [(arr[0] + arr[2]) / 2, (arr[1] + arr[3]) / 2];
          mapRef.current.getView().setCenter(mapcenter);
          mapRef.current.getView().setZoom(11);
        }
      });

      //? Remove the Marker Layer to display only the Plan layer
      mapRef.current.removeLayer(markersLayer.current);

      //? Code For Drainage Layer
      let DrainageLayer = await getVectorLayers(
        "drainage",
        district.label.toLowerCase() +
        "_" +
        block.label.toLowerCase(),
        true,
        true,
        "drainage",
        0,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      );

      DrainageLayer.setStyle(function (feature) {
        let order = feature.values_.ORDER;

        return new Style({
          stroke: new Stroke({
            color: `#${drainageColors[order - 1]}`,
            width: 2.0,
          }),
        });
      });

      if (LayersArray[1].LayerRef.current != null && currentLayers.includes(LayersArray[2].name)) {
        mapRef.current.removeLayer(LayersArray[1].LayerRef.current);
      }

      //mapRef.current.addLayer(DrainageLayer);
      LayersArray[1].LayerRef.current = DrainageLayer;
      //currentActiveLayers.push(LayersArray[1].name)

      //? Code for Remote Sensed Waterbodies Layer

      let RemoteSensedWaterbodiesLayer = await getVectorLayers(
        "water_bodies",
        "surface_waterbodies_" +
        block.label.toLowerCase(),
        true,
        true,
        "Water_bodies",
        null,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      );

      RemoteSensedWaterbodiesLayer.setStyle(
        new Style({
          stroke: new Stroke({ color: "#6495ed", width: 5 }),
          fill: new Fill({ color: "rgba(100, 149, 237, 0.5)" }),
        })
      );

      if (LayersArray[2].LayerRef.current != null && currentLayers.includes(LayersArray[2].name)) {
        mapRef.current.removeLayer(LayersArray[2].LayerRef.current);
      }

      //mapRef.current.addLayer(RemoteSensedWaterbodiesLayer);
      LayersArray[2].LayerRef.current = RemoteSensedWaterbodiesLayer;

      //? Code For MicroWatershed Layer

      let MicroWaterShedLayer = await getVectorLayers(
        "mws_layers",
        "deltaG_well_depth_" + block.label.toLowerCase(),
        true,
        true,
        "Watershed",
        null,
        district.label.toLowerCase(),
        block.label.toLowerCase()
      )

      if (LayersArray[3].LayerRef.current != null && currentLayers.includes(LayersArray[3].name)) {
        mapRef.current.removeLayer(LayersArray[3].LayerRef.current);
      }

     // mapRef.current.addLayer(MicroWaterShedLayer);
      LayersArray[3].LayerRef.current = MicroWaterShedLayer;

      //? Code For Clart Layer
      let clartLayer = await getImageLayer(
        "clart",
        "CLART_" +  block.label.toLowerCase()
      );

      if (LayersArray[4].LayerRef.current != null && currentLayers.includes(LayersArray[4].name)) {
        mapRef.current.removeLayer(LayersArray[4].LayerRef.current);
      }
      
      //mapRef.current.addLayer(clartLayer);
      LayersArray[4].LayerRef.current = clartLayer;

      //? Code for Well Depth Layer
      let wellDepthLayer = MicroWaterShedLayer;
      
      LayersArray[5].LayerRef.current = wellDepthLayer;

      //? Code For NREGA Layer

      let NregaLayer = await getWebGlLayers(
        "nrega_assets",
        district.label.toLowerCase() +
        "_" +
        block.label.toLowerCase(),
      );

      if (LayersArray[6].LayerRef.current != null && currentLayers.includes(LayersArray[6].name)) {
        mapRef.current.removeLayer(LayersArray[6].LayerRef.current);
      }

      mapRef.current.addLayer(NregaLayer);
      LayersArray[6].LayerRef.current = NregaLayer;
      currentActiveLayers.push(LayersArray[6].name)

      //? Code for Drought Layer
      let DroughtLayer = await getVectorLayers(
        "cropping_drought",
        district.label.toLowerCase() + 
        "_" + 
        block.label.toLowerCase() + "_drought",
        true,
        true
      )

      if (LayersArray[7].LayerRef.current != null && currentLayers.includes(LayersArray[7].name)) {
        mapRef.current.removeLayer(LayersArray[7].LayerRef.current);
      }

      //mapRef.current.addLayer(DroughtLayer);
      LayersArray[7].LayerRef.current = DroughtLayer;


      //? Code for Terrain Layer
      let TerrainLayer = await getImageLayer(
        "terrain",
        district.label.toLowerCase() + 
        "_" + 
        block.label.toLowerCase()
      );

      if (LayersArray[8].LayerRef.current != null && currentLayers.includes(LayersArray[8].name)) {
        mapRef.current.removeLayer(LayersArray[8].LayerRef.current);
      }
      
      //mapRef.current.addLayer(TerrainLayer);
      LayersArray[8].LayerRef.current = TerrainLayer;

      //? Code for Admin Without Metadata Layer
      let AdminBoundaryLayer = await getVectorLayers(
        "admin_boundaries",
        district.label.toLowerCase() + 
        "_" + 
        block.label.toLowerCase() + "_boundaries",
        true,
        true
      )

      if (LayersArray[9].LayerRef.current != null && currentLayers.includes(LayersArray[9].name)) {
        mapRef.current.removeLayer(LayersArray[9].LayerRef.current);
      }
      
      //mapRef.current.addLayer(AdminBoundaryLayer);
      LayersArray[9].LayerRef.current = AdminBoundaryLayer;

      setCurrentLayers(currentActiveLayers)
      setIsLoading(false);
      setIsLayersFetched(true)
    }
  };

  const handleMenuToggle = (setter, state) => {
    setter(!state);
  };

  const handleItemSelect = (setter, state, toggle) => {
    setter(state);
    toggle(false);
  };
  
  const handleGeoJsonLayers = async(layerRef, name) => {
    if (layerRef.current != null) {
      const format = new GeoJSON({ featureProjection: "EPSG:4326" });

      const features = layerRef.current.getSource().getFeatures();

      let json

      console.log("Reached Here in geojson",name)

      if(name === "Hydrological Boundries Layer"){
        let len = features.length;
        let temp_Features = [];

        for(let i = 0; i < len; ++i){
          let temp_geo = features[i].getGeometry()
          let tempFeature = new Feature({
            geometry : temp_geo
          })
          temp_Features.push(tempFeature)
        }
        
        json = format.writeFeatures(temp_Features);
      }
      else{
        json = format.writeFeatures(features);
      }

        setHrefData(
          "data:application/json;charset=utf-8," + encodeURIComponent(json)
        );
    }
  };

  const handleKMLLayers = (layerRef) => {
    if (layerRef.current != null) {
      const format = new KML({ featureProjection: "EPSG:4326" });

      const layerSource = layerRef.current.getSource();
      const features = layerSource.getFeatures();
      const kmlData = format.writeFeatures(features);

      setHrefData(
        "data:application/kml;charset=utf-8," + encodeURIComponent(kmlData)
      );
    }
  };

  const handleImageLayers = (name) => {
    if(bbox !== null && name === "Clart Layer"){
      const downloadurl = `https://geoserver.gramvaani.org:8443/geoserver/clart/wms?service=WMS&version=1.1.0&request=GetMap&layers=clart%3ACLART_${block.label.toLowerCase()}&bbox=${bbox[0]}%2C${bbox[1]}%2C${bbox[2]}%2C${bbox[3]}&width=800&height=750&srs=EPSG%3A4326&styles=&format=image%2Fgeotiff`
      window.open(downloadurl);
    }
    else if(bbox !== null && name === "Terrain Layer"){
     
      const downloadurl = `https://geoserver.gramvaani.org:8443/geoserver/terrain/wms?service=WMS&version=1.1.0&request=GetMap&layers=terrain%3A${district.label.toLowerCase()}_${block.label.toLowerCase()}&bbox=82.07646239567674%2C18.56898540650622%2C82.60332430981285%2C18.95921356592774&width=768&height=568&srs=EPSG%3A4326&styles=&format=image%2Fgeotiff`
      window.open(downloadurl);
    }
    else{
      console.log("Wait !")
    }
  }
  
  const handleImageLulcLayers = (type) => {
    if(bbox !== null && lulcYear1 !== null && type === "type1"){
      const downloadurl = `https://geoserver.gramvaani.org:8443/geoserver/lulc_agri/wms?service=WMS&version=1.1.0&request=GetMap&layers=lulc_agri%3ALULC_${lulcYear1}_${block.label.toLowerCase()}_resolution&bbox=${bbox[0]}%2C${bbox[1]}%2C${bbox[2]}%2C${bbox[3]}&width=768&height=568&srs=EPSG%3A4326&styles=&format=image%2Fgeotiff`
      window.open(downloadurl);
    }
    else if(bbox !== null && lulcYear2 !== null && type === "type2"){
      const downloadurl = `https://geoserver.gramvaani.org:8443/geoserver/LULC_level_2/wms?service=WMS&version=1.1.0&request=GetMap&layers=LULC_level_2%3ALULC_${lulcYear2}_${block.label.toLowerCase()}_level_2&bbox=82.10799326214934%2C18.576261760307588%2C82.5602051761751%2C18.916094432290002&width=768&height=577&srs=EPSG%3A4326&styles=&format=image%2Fgeotiff`
      window.open(downloadurl);
    }
    else if(bbox !== null && lulcYear3 !== null && type === "type3"){
      const downloadurl = `https://geoserver.gramvaani.org:8443/geoserver/LULC_level_3/wms?service=WMS&version=1.1.0&request=GetMap&layers=LULC_level_3%3ALULC_${lulcYear3}_boipariguda_level_3&bbox=82.10799326214934%2C18.576261760307588%2C82.5602051761751%2C18.916094432290002&width=768&height=577&srs=EPSG%3A4326&styles=&format=image%2Fgeotiff`
      window.open(downloadurl);
    }
    else{
      console.log("Wait !")
    }
  }

  const handleLayerToggle = (name, layerRef) => {
    if(!isLayersFetched){
      return
    }
    let tempLayer = currentLayers

    if(currentLayers.includes(name)){
      tempLayer = tempLayer.filter(function(item) {
        return item !== name
      })
      mapRef.current.removeLayer(layerRef.current);
    }
    else{
      tempLayer.push(name);
      mapRef.current.addLayer(layerRef.current);
    }

    console.log(tempLayer)
    setCurrentLayers(tempLayer)
  }

  
  return (
    <>
        <div className="h-screen mx-auto flex sm:flex-nowrap flex-wrap justify-end">
          <div className="w-full bg-gray-300 rounded-lg inset-0 flex items-end justify-start ">
            <div ref={mapElement} className="h-full w-full" />
          </div>

          <div className="w-2/6 mr-5 fixed flex items-center h-screen justify-center">
            <div className="bg-gray-500 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-25 border border-gray-100 rounded-2xl h-[90vh] flex-col w-full">
                <header className="flex justify-center items-center m-5">
                  <img src={landscape_icon} className="h-14 w-14"/>
                  <span className="text-transparent ml-3 text-3xl font-extrabold bg-clip-text bg-gradient-to-r to-emerald-500 from-sky-400">Landscape Explorer</span>
                </header>
            <div className="flex flex-col items-center mr-2 w-full h-[75vh] overflow-y-auto overflow-x-hidden">
            <h2 className="text-gray-900 text-md mb-1 font-semibold title-font text-centerfont-mono">
              Select Location
            </h2>

            <div className="relative mb-4 w-3/5">
              <button
                id="dropdown-button"
                onClick={() => handleMenuToggle(setStateToggle, stateToggle)}
                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                <span className="mr-2">
                  {state == null ? "Select State" : state.label}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  stateToggle ? "block" : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {stateData != null &&
                  stateData.map((item, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(setState, item, setStateToggle)
                          }
                        >
                          {item.label}
                        </a>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="relative mb-4 w-3/5">
              <button
                id="dropdown-button"
                onClick={() =>
                  handleMenuToggle(setDistrictToggle, districtToggle)
                }
                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                <span className="mr-2">
                  {district == null ? "Select District" : district.label}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  districtToggle && state != null ? "block" : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {state != null &&
                  state.district.map((item, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(
                              setDistrict,
                              item,
                              setDistrictToggle
                            )
                          }
                        >
                          {item.label}
                        </a>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="relative mb-4 w-3/5">
              <button
                id="dropdown-button"
                onClick={() => handleMenuToggle(setBlockToggle, blockToggle)}
                className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
              >
                <span className="mr-2">
                  {block == null ? "Select Block" : block.label}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  blockToggle && district != null ? "block" : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {district != null &&
                  district.blocks.map((items, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(setBlock, items, setBlockToggle)
                          }
                        >
                          {items.label}
                        </a>
                      </>
                    );
                  })}
              </div>
            </div>

            <FetchButton name={isLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Loading...
                </>
              ) : (
                "Fetch Layers"
              )} onClickEvent={handleLocationChange} isDisabled = {block == null} />
              

            <div className="flex flex-wrap gap-x-4 m-3 justify-center">
            {
              LayersArray.map((item) => {
                return(
                  <div className="w-2/5 mt-3 flex flex-col justify-around bg-white rounded-lg bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-90 border" key={item.name}>
                      <div className="w-full flex justify-between text-left p-2 items-center">
                        <label className={isLayersFetched && block!== null ? 'font-semibold text-base font-mono mr-1' : 'font-semibold text-base font-mono text-gray-400 mr-1'}>{item.name}</label>
                        <ToggleButton currentLayers={currentLayers} handleCheckboxChange={() => handleLayerToggle(item.name, item.LayerRef)} checkboxId={item.name} disabled = {isLayersFetched}/>
                      </div>
                      <div className="flex w-full justify-center mb-1">
                        {!item.isRaster ? 
                        <>
                        <div className="mr-1">
                          <DownloadButton name={"GeoJson"} onClickEvent={() => handleGeoJsonLayers(item.LayerRef, item.name)} href={hrefData} download={`${item.name}features.json`} isDisabled={isLayersFetched && block!== null}/>
                        </div>
                        <div className="ml-1">
                          <DownloadButton name={"KML"} onClickEvent={() => handleKMLLayers(item.LayerRef)} href={hrefData} download={`${item.name}features.kml`} isDisabled={isLayersFetched && block!== null}/>
                        </div>
                        </> : <DownloadButton name={"GeoTiff"} onClickEvent={() => handleImageLayers(item.name)} isDisabled={isLayersFetched && block!== null}/>}
                      </div>
                  </div>
                )
              })
            }
            </div>  

            {/* LULC LEVEL 1 */}

              <div className="flex items-center mt-4 w-3/5">
                <div className="relative flex w-auto mr-2 align-middle select-none transition duration-200 ease-in">
                <label
                  htmlFor="toggle"
                  className={`text-md font-semibold ${
                    isLayersFetched && block!== null && block.label.toLowerCase() === "boipariguda" ?"text-gray-800" : "text-gray-400"
                  }`}
                >
                  {"LULC Layer Level 1"}
                </label>
                </div>
              </div>
        

            <div className="relative mb-2 mt-3 w-3/5">
              <button
                id="dropdown-button"
                onClick={() => {
                  if(isLayersFetched && block!== null && block.label.toLowerCase() === "boipariguda")
                    handleMenuToggle(setLulcToggle1, lulcToggle1)
                }}
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium ${
                  !isLayersFetched ? "text-gray-300" : "text-gray-700"
                } bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
              >
                <span className="mr-2">
                  {lulcYear1 == null ? "Select Year" : lulcYear1}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  lulcToggle1 ? "block" : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {yearDataLulc != null &&
                  yearDataLulc.map((item, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(setLulcYear1, item.value, setLulcToggle1)
                          }
                        >
                          {item.label}
                        </a>
                      </>
                    );
                  })}
              </div>

              <div className="flex">
                    <a
                      className={`flex mx-auto mt-2 ${lulcYear1 != null?'text-white bg-emerald-600 ' : 'text-gray-400 bg-gray-300' } border-0 py-2 px-8 focus:outline-none  rounded text-sm cursor-pointer`}
                      onClick={() => handleImageLulcLayers("type1")}
                      href={hrefData}
                      download="KMLfeatures.kml"
                    >
                      GeoTiff
                    </a>
                </div>
            </div>
            {/* LULC LEVEL 2 */}

              <div className="flex items-center mt-4 w-3/5">
                <div className="relative flex w-auto mr-2 align-middle select-none transition duration-200 ease-in">
                <label
                  htmlFor="toggle"
                  className={`text-md font-semibold ${
                    isLayersFetched && block!== null && block.label.toLowerCase() === "boipariguda" ?"text-gray-800" : "text-gray-400"
                  }`}
                >
                  {"LULC Layer Level 2"}
                </label>
                </div>
              </div>
        

            <div className="relative mb-2 mt-3 w-3/5">
              <button
                id="dropdown-button"
                onClick={() => {
                  if(isLayersFetched && block!== null && block.label.toLowerCase() === "boipariguda")
                    handleMenuToggle(setLulcToggle2, lulcToggle2)
                }}
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium ${
                  !isLayersFetched ? "text-gray-300" : "text-gray-700"
                } bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
              >
                <span className="mr-2">
                  {lulcYear2 == null ? "Select Year" : lulcYear2}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  lulcToggle2 ? "block" : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {yearDataLulc != null &&
                  yearDataLulc.map((item, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(setLulcYear2, item.value, setLulcToggle2)
                          }
                        >
                          {item.label}
                        </a>
                      </>
                    );
                  })}
              </div>

              <div className="flex">
                    <a
                      className={`flex mx-auto mt-2 ${lulcYear2 != null?'text-white bg-emerald-600 ' : 'text-gray-400 bg-gray-300' } border-0 py-2 px-8 focus:outline-none  rounded text-sm cursor-pointer`}
                      onClick={() => handleImageLulcLayers("type2")}
                      href={hrefData}
                      download="KMLfeatures.kml"
                    >
                      GeoTiff
                    </a>
                </div>
            </div>

            {/* LULC LEVEL 3 */}

            <div className="flex items-center mt-4 w-3/5">
                <div className="relative flex w-auto mr-2 align-middle select-none transition duration-200 ease-in">
                <label
                  htmlFor="toggle"
                  className={`text-md font-semibold ${
                    isLayersFetched && block!== null && block.label.toLowerCase() === "boipariguda" ?"text-gray-800" : "text-gray-400"
                  }`}
                >
                  {"LULC Layer Level 3"}
                </label>
                </div>
              </div>
        

            <div className="relative mb-2 mt-3 w-3/5">
              <button
                id="dropdown-button"
                onClick={() => {
                  if(isLayersFetched && block!== null && block.label.toLowerCase() === "boipariguda")
                    handleMenuToggle(setLulcToggle3, lulcToggle3)
                }}
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium ${
                  !isLayersFetched ? "text-gray-300" : "text-gray-700"
                } bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
              >
                <span className="mr-2">
                  {lulcYear3 == null ? "Select Year" : lulcYear3}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  lulcToggle3 ? "block" : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {yearDataLulc != null &&
                  yearDataLulc.map((item, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(setLulcYear3, item.value, setLulcToggle3)
                          }
                        >
                          {item.label}
                        </a>
                      </>
                    );
                  })}
              </div>

              <div className="flex">
                    <a
                      className={`flex mx-auto mt-2 ${lulcYear3 != null?'text-white bg-emerald-600 ' : 'text-gray-400 bg-gray-300' } border-0 py-2 px-8 focus:outline-none  rounded text-sm cursor-pointer`}
                      onClick={() => handleImageLulcLayers("type3")}
                      href={hrefData}
                      download="KMLfeatures.kml"
                    >
                      GeoTiff
                    </a>
                </div>
            </div>

            <hr className="w-60 h-0.5 bg-gray-400 opacity-30 rounded-full self-center mt-4" />


            <div className="relative mt-3 w-3/5">
              <button
                id="dropdown-button"
                onClick={() => handleMenuToggle(setPlanToggle, planToggle)}
                className={`inline-flex justify-center w-full px-4 py-2 text-sm font-medium ${
                  plans == null ? "text-gray-300" : "text-gray-700"
                } bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
              >
                <span className="mr-2">
                  {selectedPlan == null ? "Select Plan" : selectedPlan.plan}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <div
                id="dropdown-menu"
                className={`${
                  block != null && plans != null && planToggle
                    ? "block"
                    : "hidden"
                } z-10 absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1 h-32 overflow-auto`}
              >
                <input
                  id="search-input"
                  className="block w-full px-4 py-2 text-gray-800 border rounded-md  border-gray-300 focus:outline-none"
                  type="text"
                  placeholder="Search items"
                  autoComplete="off"
                />

                {plans != null &&
                  plans.map((item, idx) => {
                    return (
                      <>
                        <a
                          href="#"
                          key={idx}
                          className="block px-4 py-2 text-gray-700 hover:bg-gray-100 active:bg-blue-100 cursor-pointer rounded-md"
                          onClick={() =>
                            handleItemSelect(
                              setSelectedPlan,
                              item,
                              setPlanToggle
                            )
                          }
                        >
                          {item.plan}
                        </a>
                      </>
                    );
                  })}
              </div>
            </div>

            <button
              className={`flex mx-auto mt-2 ${isLayersFetched ?'text-white bg-emerald-600 ' : 'text-gray-400 bg-gray-300' } border-0 py-2 px-8 focus:outline-none  rounded text-sm cursor-pointer`}
              onClick={()=>{
                fetchResourcesLayers()
                fetchPlanningLayers()
              }}
            >
              {isLayersLoading ? (
                <>
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline mr-3 w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    ></path>
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  Loading...
                </>
              ) : (
                "Fetch Plans"
              )}
            </button>

            <div className="flex-col gap-x-4 ml-10 mt-5 mb-2 w-full">
            
              <h1 className="text-2xl title-font font-semibold mb-2 text-gray-300">Resources Layers</h1>
              <div className="h-0.5 w-36 bg-gray-400 rounded"></div>

            </div>  
            
            <div className="flex flex-wrap gap-x-4 m-3 justify-center w-full">
            {
              ResourceLayersArray.map((item) => {
                return(
                  <div className="w-2/5 mt-3 flex flex-col justify-around bg-white rounded-lg bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-90 border" key={item.name}>
                    <div className="w-full flex justify-between text-left p-2 items-center">
                      <label className={isOtherLayersFetched && block!== null ? 'font-semibold text-base font-mono' : 'font-semibold text-base font-mono text-gray-400'}>{item.name}</label>
                      <ToggleButton currentLayers={currentLayers} handleCheckboxChange={() => console.log("toggles")} checkboxId={item.name} disabled = {isOtherLayersFetched}/>
                    </div>
                    <div className="flex w-full justify-center mb-1">
                      <div className="mr-1">
                        <DownloadButton name={"GeoJson"} onClickEvent={() => handleGeoJsonLayers(item.LayerRef, item.name)} href={hrefData} download={`${item.name}features.json`} isDisabled={isOtherLayersFetched}/>
                      </div>
                      <div className="ml-1">
                        <DownloadButton name={"KML"} onClickEvent={() => handleKMLLayers(item.LayerRef)} href={hrefData} download={`${item.name}features.kml`} isDisabled={isOtherLayersFetched}/>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            </div>

            <div className="flex-col gap-x-4 ml-10 mt-5 mb-2 w-full">
            
              <h1 className="text-2xl title-font font-semibold mb-2 text-gray-300">Planning Layers</h1>
              <div className="h-0.5 w-36 bg-gray-400 rounded"></div>

            </div>  

            <div className="flex flex-wrap gap-x-4 m-3 justify-center w-full">
            {
              PlanningLayersArray.map((item) => {
                return(
                  <div className="w-2/5 mt-3 flex flex-col justify-around bg-white rounded-lg bg-clip-padding backdrop-filter backdrop-blur-md bg-opacity-90 border" key={item.name}>
                    <div className="w-full flex justify-between text-left p-2 items-center">
                      <label className={isOtherLayersFetched && block!== null ? 'font-semibold text-base font-mono' : 'font-semibold text-base font-mono text-gray-400'}>{item.name}</label>
                      <ToggleButton currentLayers={currentLayers} handleCheckboxChange={() => console.log("toggles")} checkboxId={item.name} disabled={isOtherLayersFetched}/>
                    </div>
                    <div className="flex w-full justify-center mb-1">
                      <div className="mr-1">
                        <DownloadButton name={"GeoJson"} onClickEvent={() => handleGeoJsonLayers(item.LayerRef, item.name)} href={hrefData} download={`${item.name}features.json`} isDisabled={isOtherLayersFetched}/>
                      </div>
                      <div className="ml-1">
                        <DownloadButton name={"KML"} onClickEvent={() => handleKMLLayers(item.LayerRef)} href={hrefData} download={`${item.name}features.kml`} isDisabled={isOtherLayersFetched}/>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            </div>

          </div>
            </div>
          </div>

        </div>
    </>
  );
};

export default MapArea;
